import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { cwTheme } from '../theme/theme.js';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Button from '../components/Button';
import SignUpForm from '../components/SignUpForm';

const Date = styled.span`
	margin-bottom: 1em;
	color: #bbb;
	display: inline-block;
`;

const ServiceHero = styled.section`
	background-color: rgba(255, 255, 255, 1);

	text-align: center;
	padding: 0 0 4rem 0;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 1rem 0 4rem 0;
	}
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const Header = styled.h2`
	display: block;
	font-weight: 800;
	max-width: 500px;

	margin: 0 auto;
`;

const SubHeader = styled.span`
	display: block;
	max-width: 500px;
	margin: 0 auto;
`;

const ServiceIndex = styled.section`
	display: grid;
	grid-template-rows: auto;
	// @media (min-width: ${cwTheme.mediumBreakPoint}) {
	// 	grid-template-columns: 1fr 1fr;
	// }
`;

const Service = styled.a`
	flex: 1;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
	color: #f1f1f1;
	background-color: ${(props) => props.color};
	text-decoration: none;
	transition: 0.3s;
	text-align: center;
	span {
		max-width: 500px;
		margin: 0 auto;
		line-height: 1.18421053em;
	}
	&:hover {
		background-color: #f1f1f1;
		color: #000;
	}
	// @media (min-width: ${cwTheme.mediumBreakPoint}) {
	// 	min-height: 300px;
	// }
`;

const ServiceHome = ({ data, pageContext }) => {
	console.log(data);
	console.log(pageContext);

	const { basePath } = pageContext;

	const services = data.allContentfulService.edges;
	const serviceHeader = data.contentfulServiceIndexHeader;

	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title="Offerings" />

				<ServiceHero>
					<Container>
						<Header>{serviceHeader.headline}</Header>
						<SubHeader>{serviceHeader.subheadline.subheadline}</SubHeader>
					</Container>
				</ServiceHero>

				<ServiceIndex>
					{services.map(({ node: service }, i) => (
						<Service key={i} href={basePath + service.slug} color={service.backgroundColor}>
							<span>{service.title}</span>
						</Service>
					))}
				</ServiceIndex>

				<SignUpForm />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query {
		allContentfulService(sort: { fields: [sortOrder], order: ASC }) {
			totalCount
			edges {
				node {
					title
					id
					sortOrder
					slug
					backgroundColor
				}
			}
		}
		contentfulServiceIndexHeader {
			headline
			subheadline {
				subheadline
			}
		}
	}
`;

export default ServiceHome;
